// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-customer-photos-page-js": () => import("./../../../src/templates/customer-photos-page.js" /* webpackChunkName: "component---src-templates-customer-photos-page-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/default-page.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-detail-page-js": () => import("./../../../src/templates/product-detail-page.js" /* webpackChunkName: "component---src-templates-product-detail-page-js" */),
  "component---src-templates-products-page-js": () => import("./../../../src/templates/products-page.js" /* webpackChunkName: "component---src-templates-products-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */)
}

