export const getShopifyLocaleFields = (object, locale) => {
  return { ...object, ...object.translations[locale] };
};

export const getCollectionLocaleFields = (object, locale) => {
  return object.group.find(({ fieldValue }) => fieldValue === locale);
};

export const getSingleLocaleFields = (object, locale) => {
  return object.nodes.find(node => node.locale === locale);
};

export const translateString = (name, locale) => {
  const translations = {
    en: {
      search: {
        page: 'Search Bobbi Beck',
        box: 'Find your perfect wallpaper',
        result: 'result',
        for: 'for',
        categoriesText: 'Categories',
      },
      product: {
        perRoll: 'per roll',
        perM: 'per m',
        perFt: 'per ft',
        select: 'Select a',
        paperTypeQuestion: 'What does this mean?',
        popular: 'popular',
        addToCart: 'Add to cart',
        contact: 'Contact',
        rollsQuestion: 'Unsure how many rolls? Try our',
        rollCalculator: 'Roll estimator',
        roomVisualiser: 'View in room visualiser',
        otherCategoriesHeading: 'Browse more categories:',
        recentlyViewedHeading: 'Recently Viewed',
        reviewIntro: 'Reviews from customers who bought',
        relatedHeading: 'You may also like',
        orderSamples: 'Order free samples for',
        sampleListItems: [
          'Order up to 10 [size] samples',
          'With you as quickly as 3 working days',
          'Delivery fees refundable on any future order',
        ],
        orderSamplesButton: 'Order Samples',
        taxNote: 'inc. taxes & duties',
        productInfoHeading: 'Product Information',
        learnPaperTypes: 'Learn more about paper types',
        customisation: 'Learn more about customisation',
        mockup: 'Learn more about our free mockup service',
        dispatchNoticeFree:
          'Order today and your wallpaper will arrive on or before',
        dispatchNotice:
          'Order today and your wallpaper will arrive on or before',
        dispatchNoticeShort: 'Arrives on or before',
        freeDelivery: 'Free delivery',
        freeDeliveryInfo: 'Free delivery for orders over',
      },
      rollCalculator: {
        heading: 'Roll Estimator',
        intro:
          'This calculator provides an estimate of how many rolls you need. It considers the design repeat as part of its calculation but does not include any excess.',
        measureText: 'Measure in:',
        widthPlaceholder: 'Total wall width',
        heightPlaceholder: 'Total wall height',
        dimensions: 'Dimensions',
        rolls: 'Rolls',
        errorMessage:
          'This wall is taller than a full roll of wallpaper. Contact our team for help before ordering.',
        subTotal: 'Subtotal',
      },
      productInfo: {
        sku: 'SKU',
        rollWidth: 'Roll Width',
        rollHeight: 'Roll Height',
        patternMatch: 'Pattern Match',
        verticalPatternRepeat: 'Vertical Pattern Repeat',
        installMethod: 'Install Method',
        match: {
          no: 'No Match',
          straight: 'Straight Match',
          drop: 'Drop Match',
        },
      },
      croppingTool: {
        heading: 'Create Your Mural',
        helperText:
          'Move the highlighted area to select your crop. If you need help with cropping or colour customisation',
        contact: 'contact us',
        enterDimensions: 'Enter your wall dimensions',
        widthPlaceholder: 'Width',
        heightPlaceholder: 'Height',
        terms:
          'I confirm that I’m happy with the highlighted cropped area on the image shown.',
        totalPrice: 'Total Price',
      },
      productTabs: {
        descriptionHeading: 'Product Description',
        specHeading: 'Product Spec',
      },
      sidebar: {
        back: 'Go Back',
      },
      samples: {
        header: 'Paper Samples',
        addToCart: 'Add sample to cart',
        orderSamples: `Order '[coolName]' Samples`,
      },
      cart: {
        header: 'Cart',
        remove: 'Remove',
        subtotal: 'Subtotal',
        message: 'Shipping, taxes and trade discount calculated at checkout.',
        checkout: 'Checkout',
        empty: 'Your Cart is Empty',
        continue: 'Continue Shopping',
      },
      other: {
        before: 'Before',
        after: 'After',
      },
      locationSettings: {
        header: 'Location Settings',
        currency: 'Currency',
        language: 'Language',
      },
      languages: {
        english: 'English',
        french: 'French',
      },
      monthsOfYear: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    fr: {
      search: {
        page: 'Je cherche',
        box: 'Je cherche',
        result: 'résultat',
        for: 'pour',
        categoriesText: 'Catégories',
      },
      product: {
        perRoll: 'le rouleau',
        perM: '/ m',
        perFt: '/ ft',
        select: 'Sélectionner une',
        contactColours: 'Contactez-nous pour les couleurs personnalisées',
        paperTypeQuestion: `Qu'est-ce que cela signifie?`,
        popular: 'populaire',
        addToCart: 'Aujouter au panier',
        customiseBuy: 'Personnaliser & Commander',
        contact: 'Nous contacter',
        rollsQuestion:
          'Quelle quantité de papier peint me faut-il?\n\n Utilisez notre calculateur de rouleaux pour déterminer le nombre de rouleaux nécessaire pour votre projet.\n\n',
        rollCalculator: 'Calculateur de rouleaux',
        roomVisualiser: 'Visualiseur en situation',
        otherCategoriesHeading: 'Collections Populaires',
        recentlyViewedHeading: 'Derniers papiers peints vus',
        reviewIntro: 'Avis des clients qui ont choisi',
        relatedHeading: 'Vous aimerez aussi',
        orderSamples: 'Commander des échantillons gratuits du design',
        sampleListItems: [
          "Commandez jusqu'à 10 échantillons A4",
          'Avec vous sous 3 jours ouvrés',
          'Frais de livraison remboursables sur toute commande future',
        ],
        orderSamplesButton: 'Commander des échantillons',
        taxNote: 'inc. taxes & duties',
        productInfoHeading: 'Information sur le produit',
        learnPaperTypes: 'En savoir plus sur nos types de papier',
        customisation: 'En savoir plus sur notre service de personnalisation',
        mockup: 'En savoir plus sur notre service de visualisation 3D',
        dispatchNoticeFree: `Commandez aujourd'hui pour arriver au plus tard le`,
        dispatchNotice: `Avec vous au plus tard le [dispatchDate] si vous placez votre commande aujourd'hui`,
        dispatchNoticeShort: 'Avec vous au plus tard le',
        freeDelivery: 'Livraison gratuite',
        freeDeliveryInfo: 'Livraison gratuite pour toute commande supérieure à',
      },
      rollCalculator: {
        heading: 'Estimateur de roulis',
        intro: `Ce calculateur fournit une estimation du nombre de rouleaux dont vous avez besoin. Il considère la répétition de conception comme faisant partie de son calcul mais n'inclut aucun excédent.`,
        measureText: 'Mesurer en:',
        widthPlaceholder: 'Largeur totale du mur',
        heightPlaceholder: 'Hauteur totale du mur',
        dimensions: 'Dimensions',
        rolls: 'Rouleaux',
        errorMessage: `Ce mur est plus haut qu'un rouleau complet de papier peint. Contactez notre équipe pour obtenir de l'aide avant de commander.`,
        subTotal: 'Sous-total',
      },
      productInfo: {
        sku: 'Référence UGS',
        rollWidth: 'Largeur du rouleau',
        rollHeight: 'Hauteur du rouleau',
        patternMatch: 'Type de raccord',
        verticalPatternRepeat: 'Taille du raccord',
        installMethod: 'Méthode de pose',
        match: {
          no: 'Raccord libre',
          straight: 'Raccord droit',
          drop: 'Raccord sauté',
        },
      },
      croppingTool: {
        heading: 'Créez votre papier peint panoramique sur mesure',
        helperText: `Déplacez la zone en surbrillance pour sélectionner votre recadrage. Si vous avez besoin d'aide pour le recadrage ou la personnalisation des couleurs`,
        contact: 'contactez-nous',
        enterDimensions: 'Entrer les dimensions de votre mur',
        widthPlaceholder: 'Largeur',
        heightPlaceholder: 'Hauteur',
        terms: `Je confirme que la zone recadrée mise en évidence sur l'image présentée me convient.`,
        totalPrice: 'Total Price',
      },
      productTabs: {
        descriptionHeading: 'Description du produit',
        specHeading: 'Spécification du produit',
      },
      sidebar: {
        back: 'Retourner',
      },
      samples: {
        header: 'Échantillons',
        addToCart: 'Ajouter gratuitement au panier',
        orderSamples: 'Je veux un échantillon de [coolName]',
      },
      cart: {
        header: 'Panier',
        remove: 'Retirer',
        subtotal: 'Sous-total',
        message:
          'Frais de livraison et réductions pour clients pro calculés à la caisse',
        checkout: 'Valider mon panier',
        empty:
          'Oh, votre panier est vide!\n\nNous avons plein de jolies idées pour vous aider à le remplir',
        continue: "Je m'inspire",
      },
      other: {
        before: 'Avant',
        after: 'Après',
        free: 'Gratuit',
      },
      locationSettings: {
        header: 'Devise et langue',
        currency: 'Devise',
        language: 'Langue',
      },
      languages: {
        english: 'Anglais',
        french: 'Français',
      },
      monthsOfYear: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
    },
  };

  return name.split('.').reduce((a, b) => a[b], translations[locale]);
};
