export const getDescriptiveName = metafields => {
  const descriptiveName = metafields.find(
    ({ key }) => key === 'descriptive_name'
  );

  return descriptiveName && descriptiveName.value;
};

export const getColourHex = metafields => {
  return metafields.find(({ key }) => key === 'colour').value;
};
