import gbFlag from '../images/gb-flag.svg';
import usFlag from '../images/us-flag.svg';
import euFlag from '../images/eu-flag.svg';
import auFlag from '../images/au-flag.svg';
import caFlag from '../images/ca-flag.svg';
import nzFlag from '../images/nz-flag.svg';
import hkFlag from '../images/hk-flag.svg';
import chFlag from '../images/ch-flag.svg';
import aeFlag from '../images/ae-flag.svg';
import saFlag from '../images/sa-flag.svg';

export const getFlag = currency => {
  let flag;

  switch (currency) {
    case 'USD':
      flag = usFlag;
      break;
    case 'EUR':
      flag = euFlag;
      break;
    case 'AUD':
      flag = auFlag;
      break;
    case 'CAD':
      flag = caFlag;
      break;
    case 'NZD':
      flag = nzFlag;
      break;
    case 'HKD':
      flag = hkFlag;
      break;
    case 'CHF':
      flag = chFlag;
      break;
    case 'AED':
      flag = aeFlag;
      break;
    case 'SAR':
      flag = saFlag;
      break;
    default:
      flag = gbFlag;
  }

  return flag;
};
