import { getShopifyLocaleFields } from './Locale';

export const getProductCards = (datoCmsProducts, shopifyProducts, locale) => {
  const products = datoCmsProducts.map(datoCmsProduct => {
    const shopifyProduct = shopifyProducts.find(
      ({ handle }) => handle === datoCmsProduct.shopifyProduct
    );

    return {
      ...datoCmsProduct,
      ...getShopifyLocaleFields(shopifyProduct, locale),
    };
  });

  return products;
};
