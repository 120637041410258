const React = require('react');
const { StoreProvider } = require('./src/context/StoreContext');

exports.onRouteUpdate = ({ location }) => {
  const urlParams = new URLSearchParams(location.search);
  const currencyParam = urlParams.get('currency');

  if (currencyParam) {
    // Set a different local storage if we've already got a currency set

    if (
      localStorage.getItem(`${process.env.GATSBY_SHOPIFY_SHOP_NAME}:currency`)
    ) {
      localStorage.setItem(
        `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:currency-change`,
        currencyParam
      );
    }

    localStorage.setItem(
      `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:currency`,
      currencyParam
    );
  }
};

exports.wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
);
