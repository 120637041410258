import React, { createContext, useState, useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import Client from 'shopify-buy';
import { currencyOptions } from '../utils';

const client = Client.buildClient({
  domain: process.env.GATSBY_CUSTOM_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  // language: 'fr',
});

const defaultValues = {
  isOverlayActive: false,
  setOverlayActive: () => {},

  currency: 'GBP',
  currencyLoaded: false,
  changeCurrency: () => {},
  isLocationSettingsOpen: false,
  toggleLocationSettingsOpen: () => {},

  isCartOpen: false,
  toggleCartOpen: () => {},

  client,
  checkout: {
    id: 0,
    lineItems: [],
    subtotalPriceV2: 0,
    webUrl: '',
  },
  addProductsToCheckout: () => {},
  updateProductInCheckout: () => {},
  removeProductFromCheckout: () => {},
  addCustomerInfoToCheckout: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive
  );
  const [currency, setCurrency] = useState(defaultValues.currency);
  const [currencyLoaded, setCurrencyLoaded] = useState(
    defaultValues.currencyLoaded
  );
  const [isLocationSettingsOpen, setLocationSettingsOpen] = useState(
    defaultValues.isLocationSettingsOpen
  );
  const [isCartOpen, setCartOpen] = useState(defaultValues.isCartOpen);
  const [checkout, setCheckout] = useState(defaultValues.checkout);

  const isBrowser = typeof window !== 'undefined';
  const localStorageCurrencyKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:currency`;
  const localStorageCurrencyChangeKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:currency-change`;
  const localStorageCheckoutIdKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:checkout-id`;

  useEffect(() => {
    initialiseCurrency();
  }, []);

  const getDefaultCurrency = async () => {
    let currency = 'GBP';

    const locale = document.documentElement.lang;

    if (locale === 'fr') {
      return 'EUR';
    }

    if (navigator.userAgent.match(/bot|spider/i)) {
      return currency;
    } else {
      try {
        const request = await fetch(
          `https://ipinfo.io/json?token=${process.env.GATSBY_IPINFO_TOKEN}`
        );
        const jsonResponse = await request.json();

        if (jsonResponse) {
          switch (jsonResponse.country) {
            case 'GB':
              currency = 'GBP';
              break;
            case 'US':
              currency = 'USD';
              break;
            case 'AU':
              currency = 'AUD';
              break;
            case 'CA':
              currency = 'CAD';
              break;
            case 'NZ':
              currency = 'NZD';
              break;
            case 'HK':
              currency = 'HKD';
              break;
            case 'CN':
              currency = 'CHF';
              break;
            case 'AE':
              currency = 'AED';
              break;
            case 'SA':
              currency = 'SAR';
              break;
            default:
              currency = 'EUR';
          }
        }
        return currency;
      } catch (e) {
        console.error(e);
        return currency;
      }
    }
  };

  const initialiseCurrency = async () => {
    try {
      const currency =
        isBrowser && localStorage.getItem(localStorageCurrencyKey)
          ? localStorage.getItem(localStorageCurrencyKey)
          : await getDefaultCurrency();

      const currencyChange =
        isBrowser && localStorage.getItem(localStorageCurrencyChangeKey)
          ? localStorage.getItem(localStorageCurrencyChangeKey)
          : null;

      const currencyFormatted = currency.toUpperCase();

      if (currencyOptions.includes(currencyFormatted)) {
        setCurrency(currencyFormatted);
        setCurrencyLoaded(true);
        localStorage.setItem(localStorageCurrencyKey, currencyFormatted);
        initialiseCheckout(currencyFormatted, currencyChange ? true : false);
      }
    } catch (e) {
      initialiseCheckout();
      console.error(e);
    }
  };

  const changeCurrency = async currency => {
    setCurrency(currency);

    localStorage.setItem(localStorageCurrencyKey, currency);

    const newCheckout = await getNewCheckout(currency);

    setCheckout(newCheckout);
  };

  const toggleLocationSettingsOpen = () => {
    setLocationSettingsOpen(!isLocationSettingsOpen);
  };

  const toggleCartOpen = () => {
    setCartOpen(!isCartOpen);
  };

  const initialiseCheckout = async (currency, force = false) => {
    const checkout = isBrowser
      ? localStorage.getItem(localStorageCheckoutIdKey)
      : null;

    const now = new Date();
    let checkoutExpiry;

    // Clean up for people that are using the existing checkout ID
    if (checkout && checkout.includes('value')) {
      checkoutExpiry = JSON.parse(checkout);
    }

    let newCheckout;

    if (
      !force &&
      checkoutExpiry &&
      now.getTime() < checkoutExpiry.expiry &&
      checkoutExpiry.value
    ) {
      try {
        newCheckout = await client.checkout.fetch(checkoutExpiry.value);

        if (
          newCheckout === null ||
          newCheckout.completedAt ||
          newCheckout.lineItems.find(({ variant }) => variant === null)
        ) {
          newCheckout = await getNewCheckout(currency);
        }
      } catch (e) {
        console.error(e);
        Sentry.captureException(
          `Checkout is null, completed or variant is unavailable: ${e}`
        );
        newCheckout = await getNewCheckout(currency);
      }
    } else {
      newCheckout = await getNewCheckout(currency);
    }

    if (force) {
      isBrowser && localStorage.removeItem(localStorageCurrencyChangeKey);
    }

    setCheckout(newCheckout);
  };

  const getNewCheckout = async currency => {
    const countryCode = currency
      ? currency === 'EUR'
        ? 'FR'
        : currency.slice(0, 2)
      : 'GB';

    try {
      const newCheckout = await client.checkout.create({
        buyerIdentity: {
          countryCode: countryCode,
        },
      });

      if (isBrowser) {
        // Set with an expiry
        const now = new Date();
        const item = {
          value: newCheckout.id,
          expiry: now.getTime() + 172800000,
        };

        localStorage.setItem(localStorageCheckoutIdKey, JSON.stringify(item));
      }

      return newCheckout;
    } catch (e) {
      console.error(e);
      Sentry.captureException(`Get new checkout: ${e}`);
      return defaultValues.checkout;
    }
  };

  const addProductsToCheckout = async lineItems => {
    try {
      const updatedCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );

      setCheckout(updatedCheckout);

      toggleCartOpen();
    } catch (e) {
      console.error(e);
    }
  };

  const updateProductInCheckout = async (lineItemId, quantity) => {
    try {
      const updatedCheckout = await client.checkout.updateLineItems(
        checkout.id,
        [
          {
            id: lineItemId,
            quantity: quantity,
          },
        ]
      );

      setCheckout(updatedCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  const removeProductFromCheckout = async lineItemId => {
    try {
      const updatedCheckout = await client.checkout.removeLineItems(
        checkout.id,
        [lineItemId]
      );

      setCheckout(updatedCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  const addCustomerInfoToCheckout = async (type, vatNumber, eoriNumber) => {
    const customAttributes = [{ key: 'Customer Type', value: type }];

    if (type === 'Business') {
      customAttributes.push(
        { key: 'VAT Number', value: vatNumber },
        { key: 'EORI Number', value: eoriNumber }
      );
    }

    try {
      const updatedCheckout = await client.checkout.updateAttributes(
        checkout.id,
        {
          customAttributes: customAttributes,
        }
      );

      setCheckout(updatedCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        setOverlayActive,

        currency,
        currencyLoaded,
        changeCurrency,
        isLocationSettingsOpen,
        toggleLocationSettingsOpen,

        isCartOpen,
        toggleCartOpen,

        checkout,
        addProductsToCheckout,
        updateProductInCheckout,
        removeProductFromCheckout,
        addCustomerInfoToCheckout,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
