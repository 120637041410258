import { translateString } from './Locale';

export const getDispatchDate = (
  leadTimeExcludedDates,
  leadTimeDays,
  locale
) => {
  const isDateExcluded = date => {
    const isExcluded = leadTimeExcludedDates.find(
      ({ excludedDate }) =>
        excludedDate ===
        `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
          '0' + date.getDate()
        ).slice(-2)}`
    );

    const isWeekend = date.getDay() === 6 || date.getDay() === 0;

    if (isExcluded || isWeekend) {
      return true;
    } else {
      return false;
    }
  };

  const getCutOffDate = () => {
    const todaysDate = new Date();
    let excludedDays = 0;
    const cutOffDate = new Date();
    let dateExcluded;

    do {
      const nextCutOffDate = new Date();
      nextCutOffDate.setDate(todaysDate.getDate() + excludedDays);

      dateExcluded = isDateExcluded(nextCutOffDate);

      if (dateExcluded) {
        excludedDays++;
      }
    } while (dateExcluded);

    cutOffDate.setDate(todaysDate.getDate() + excludedDays);

    return cutOffDate;
  };

  const monthsOfYear = translateString('monthsOfYear', locale);

  const cutOffDate = getCutOffDate();

  let daysUntilDispatch = leadTimeDays;

  const dispatchDate = cutOffDate;

  for (let i = 0; i < daysUntilDispatch; i++) {
    const nextDispatchDate = new Date(cutOffDate.getTime());
    nextDispatchDate.setDate(cutOffDate.getDate() + i);

    if (isDateExcluded(nextDispatchDate)) {
      daysUntilDispatch++;
    }
  }

  dispatchDate.setDate(cutOffDate.getDate() + (daysUntilDispatch - 1));

  const getMonthOfYear = () => monthsOfYear[dispatchDate.getMonth()];

  return `${locale === 'en' ? getMonthOfYear() : dispatchDate.getDate()} ${
    locale === 'en' ? dispatchDate.getDate() : getMonthOfYear()
  }`;
};
