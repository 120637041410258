import { useContext } from 'react';
import { StoreContext } from '../context/StoreContext';
import { translateString } from './Locale';

export const currencyOptions = [
  'GBP',
  'USD',
  'EUR',
  'AUD',
  'CAD',
  'NZD',
  'HKD',
  'CHF',
  'AED',
  'SAR',
];

export const getVariantCurrencyPrice = (variantPresentmentPrices, currency) => {
  const variantCurrencyPrice = variantPresentmentPrices.find(
    ({ price: { currencyCode } }) => currencyCode === currency
  ).price;

  return variantCurrencyPrice;
};

export const useVariantPrice = variant => {
  const { currency } = useContext(StoreContext);

  const variantPrice = getVariantCurrencyPrice(
    variant.presentmentPrices,
    currency
  );

  return variantPrice;
};

export const formatPrice = ({
  amount,
  currencyCode = 'GBP',
  locale = 'en-GB',
}) => {
  amount = parseFloat(amount);

  const currencyFormatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  });

  const formattedPrice =
    currencyCode === 'USD'
      ? currencyFormatter.format(amount).replace('US', '')
      : currencyFormatter.format(amount);

  return amount
    ? formattedPrice
    : locale === 'fr'
    ? translateString('other.free', locale)
    : formattedPrice.replace('0.00', ' Free');
};
