export const buildUrl = (slug, page) => {
  let url = '/';

  if (page && page.locale === 'fr') {
    url += 'fr/';
  }

  if (page && page.internal && page.internal.type) {
    if (page.internal.type === 'DatoCmsProductCategory') {
      url += page.locale === 'fr' ? 'papier-peint/' : 'wallpaper/';
    } else if (page.internal.type === 'DatoCmsProduct') {
      url += page.locale === 'fr' ? 'produits/' : 'products/';
    } else if (page.internal.type === 'DatoCmsProject') {
      url += page.locale === 'fr' ? 'projets/' : 'projects/';
    }
  }

  url += `${slug !== '/' ? `${slug.replace(/^\/|\/$/g, '')}/` : ''}`;

  return url;
};
